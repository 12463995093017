import {useQuery} from "@tanstack/react-query";
import {UserTokensResponse} from "@/api/reservoir/types";
import {Item} from "@/types/dkoda_types";

export const fetchNftOwners = async (nftAddress: string, owner: string) => {
  return await fetch(`https://api-blast.reservoir.tools/users/${owner}/tokens/v10?contract=${nftAddress}&limit=200`, {
    headers: {
      'accept': '*/*',
      'x-api-key': '29933ebc-4aa9-5b0f-a55c-8568f8668120'
    }
  })
    .then(res => res.json())
    .then(d => {
      const data: UserTokensResponse = d;
      return data.tokens.map(t => {
        const token = t.token;
        const item: Item = {
          groupId: 0,
          id: `${token.tokenId}`,
          isLiked: false,
          name: token.name,
          imageUrl: token.image,
          imageThumbnailUrl: token.imageSmall,
          tokenId: Number(token.tokenId),
          traits: [],
          transactions: []
        }
        return item;
      })
    });
}

export const useNFTOwners = (nftAddress: string, owner: string, enabled: boolean) => {
  return useQuery({
    queryKey: ['nftOwners', nftAddress, owner],
    queryFn: async () => await fetchNftOwners(nftAddress, owner),
    enabled
  });
}
