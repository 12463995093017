import {useAccount} from "wagmi";
import {ConnectWallet} from "@/components/ConnectWallet";
import {OwnerTokens, useSelectNFTs} from "@/components/ERC721/OwnerTokens";
import {fetchNftOwners} from "@/api/reservoir/useNFTOwners";
import {useQuery} from "@tanstack/react-query";
import {fetchSwapQuote} from "@/api/perpsDataFetcher";
import React, {useEffect, useState} from "react";
import {PerpToken, SwapConfig, SwapQuote} from "@/components/Perps/types";
import {WETH_TOKEN} from "@/util/chainConstants";
import {AddressZero} from "@ethersproject/constants";
import {isBlast} from "@/util/constants";
import {useUserSelection} from "@/contexts/UserSelectionContext";
import {SlippageButton} from "@/components/Perps/slippage/SlippageButton";
import {FNFTSellButton} from "@/components/Fractions/FNFTSellButton";
import {GridStyle} from "@/pages/collect";
import {useCurrentUser} from "@/hooks/useCurrentUser";
import {Address} from "viem";

export interface Props {
  token: PerpToken;
  gridStyle: GridStyle;
}

export const FNFTPortfolio = ({token, gridStyle}: Props) => {
  const {address} = useCurrentUser();
  const nftAddress = token.nftAddress!;
  const {userSelections} = useUserSelection();
  const selectedTokenIds = useSelectNFTs();
  const selectedTokenCount = selectedTokenIds?.selectedTokenIds?.length || 0;

  const [swapConfig, setSwapConfig] = useState<SwapConfig>({
    in: token,
    out: WETH_TOKEN,
    amount: BigInt((selectedTokenCount * 1000) * 10 ** 18),
    poolFee: 300,
    quoteType: "EXACT_IN",
    recipient: address as Address || AddressZero,
    useUniversalRouter: !isBlast,
    maxSlippage: userSelections.slippage,
    orderDuration: 5 * 60, // 5 minutes
    includePrices: false,
  });

  const swapQuote = useQuery({
    queryKey: ["swapQuote", swapConfig],
    queryFn: async () => fetchSwapQuote(swapConfig),
    enabled: selectedTokenCount > 0,
    gcTime: 5 * 1000,
    staleTime: 5 * 1000,
    refetchInterval: 5 * 1000,
  });

  useEffect(() => {
    setSwapConfig(prev => ({
      ...prev,
      amount: BigInt((selectedTokenCount * 1000) * 10 ** 18)
    }));
  }, [selectedTokenCount]);

  return (
    <>
      {!address && <ConnectWallet/>}
      <div
        className={`p-2 flex-grow w-full overflow-y-auto auto-rows-min no-scrollbar ${gridStyle.toString()}`}>
        {
          address &&
            <OwnerTokens
              {...selectedTokenIds}
              className="!border-none !bg-transparent h-full"
              contractAddress={nftAddress}
              owner={address}
              fetcher={fetchNftOwners}
              gridStyle={gridStyle}
            />
        }

      </div>
      <div className="w-full p-4 responsive-flex items-center md:justify-between gap-2">
        <FNFTSellButton nftAddress={nftAddress} items={selectedTokenIds.selectedTokenIds || []}  swapQuote={swapQuote.data || {} as SwapQuote}/>
        <div className="w-full md:w-max flex flex-row items-center justify-between md:justify-end gap-2">
          <SlippageButton/>
        </div>
      </div>
    </>
  )
}
