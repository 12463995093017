import {SplitLabelValue} from "@/components/SplitLabelValue";
import {Button, ButtonType} from "@/components/Button";
import React from "react";
import {WarningPanel} from "@/components/WarningPanel";
import {MAX_SLIPPAGE, WARNING_SLIPPAGE} from "@/util/constants";

export interface Props {
  initialSlippage: number; // BPS
  onSlippageChanged: (slippageBPS: number) => any;
}

const options = [1, 5, 10, 50, 100];

export const SlippageInput = ({initialSlippage, onSlippageChanged}: Props) => {
  const [slippage, setSlippage] = React.useState<number | undefined>(initialSlippage);
  return (
    <div className="standard-stack gap-2 p-4">
      <SplitLabelValue label="Max. Slippage"
                       tooltip="Your transaction will revert if the price changes unfavorably by more than this percentage.">
        <div className="standard-frame flex flex-row items-end gap-2 p-2">
          <input
            className="unstyled-input flex-grow text-right min-w-[100px] text-white"
            type="number"
            min={0}
            max={MAX_SLIPPAGE}
            step={0.01}
            value={slippage ? (slippage / 100) : undefined}
            placeholder="0.5"
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setSlippage(undefined);
              } else {
                setSlippage(parseFloat(value) * 100);
              }
            }}
          />
          <span>%</span>
        </div>
      </SplitLabelValue>
      <hr className="border-neutral-content/50" />
      <div className="flex flex-row justify-between gap-2">
        {
          options.map((option) => (
              <div className="text-neutral-content hover:text-white hover:cursor-pointer"
                   onClick={() => setSlippage(option)}
                   key={`slippage_option${option}`}>
                {option / 100}%
              </div>
          ))
        }
      </div>
      <hr className="border-neutral-content/50" />
      <Button buttonType={ButtonType.SECONDARY}
              disabled={slippage === undefined || slippage > MAX_SLIPPAGE}
              onClick={() => slippage !== undefined && onSlippageChanged(slippage)}>
        Confirm
      </Button>
      <div className="max-w-[350px]">
        {
          slippage !== undefined &&
          slippage > MAX_SLIPPAGE &&
          <WarningPanel message={`Maximum allowed slippage is ${MAX_SLIPPAGE / 100}%`} />
        }
        {
          slippage !== undefined &&
          slippage > WARNING_SLIPPAGE &&
          <WarningPanel message="Warning: A higher slippage could result in an undesirable entry price." />
        }
      </div>
    </div>
  );
}