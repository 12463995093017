import {FractionTokenPrice} from "@/components/Perps/FractionTokenPrice";

export const EthPrice = ({value}: { value: number }) => {
  return (
    <div className="flex flex-row gap-1 items-center">
      <FractionTokenPrice
        price={value}
        iconSize={12}
        exponentClassName="flex flex-row items-center text-xs text-neutral-content" />
    </div>
  )
}