import React, {useEffect} from 'react';
import {IoMdSwap} from 'react-icons/io';
import {useHasMounted} from "@/hooks/useHasMounted";
import {PerpToken} from "@/components/Perps/types";
import {ConnectWallet} from "@/components/ConnectWallet";
import {OwnerTokens, useSelectNFTs} from "@/components/ERC721/OwnerTokens";
import {fetchNftOwners} from "@/api/reservoir/useNFTOwners";
import {useCurrentUser} from "@/hooks/useCurrentUser";
import {GridStyle} from "@/pages/collect";
import {useReadIdn404MirrorLockedIds} from "@/util/generated";
import {Address, zeroAddress} from "viem";
import {FaLock, FaUnlock} from "react-icons/fa";
import {
  TransactionButton,
  TransactionButtonProps,
  useTransactionError
} from "@/components/Transactions/TransactionButton";
import {IDN404MirrorAbi} from "@/contract/IDN404MirrorAbi";
import {ErrorPanel} from "@/components/ErrorPanel";
import {useQuery} from "@tanstack/react-query";
import {ChainItem, TransactionChain} from "@/components/Transactions/TransactionChain";

export interface Props {
  token: PerpToken;
  gridStyle: GridStyle;
}

export const FNFTLockAndUnlock = ({token, gridStyle}: Props) => {
  const hasMounted = useHasMounted();
  const {address} = useCurrentUser();
  const nftAddress = token.nftAddress!;
  const lockedSelected = useSelectNFTs();
  const unlockedSelected = useSelectNFTs();
  const txnError = useTransactionError();

  const getSelectedCount = () => (lockedSelected.selectedTokenIds?.length || 0) + (unlockedSelected.selectedTokenIds?.length || 0);
  const anySelected = () => getSelectedCount() > 0;
  const isLockedNFT = () => (lockedSelected.selectedTokenIds?.length || 0) > 0;

  const lockedIds = useReadIdn404MirrorLockedIds({
    address: nftAddress as Address,
    args: [address as Address, 0n, 1000n],
  });

  const {data = [], isLoading, error, refetch} = useQuery({
    queryKey: ['nftOwners', nftAddress, address],
    queryFn: async () => await fetchNftOwners(nftAddress, address || zeroAddress),
    enabled: !!address,
  });

  const lockedNFTIds = lockedIds.data || [];

  const txnChain: ChainItem[] = [
    {
      name: "Update Lock State",
      description: "Lock or unlock selected NFTs",
      button: {
        id: "updateLockState",
        addressOrName: token.nftAddress as Address,
        contractInterface: IDN404MirrorAbi,
        functionName: "updateLockState",
        args: isLockedNFT() ? [lockedSelected.selectedTokenIds, false] : [unlockedSelected.selectedTokenIds, true],
        ...txnError,
        enabled: !!address && anySelected()
      }
    }
  ]

  useEffect(() => {
    if (lockedSelected?.selectedTokenIds && lockedSelected.selectedTokenIds.length > 0) {
      unlockedSelected.onChange([]);
    }
  }, [lockedSelected.selectedTokenIds]);

  useEffect(() => {
    if (unlockedSelected?.selectedTokenIds && unlockedSelected.selectedTokenIds.length > 0) {
      lockedSelected.onChange([]);
    }
  }, [unlockedSelected.selectedTokenIds]);

  if (isLoading) {
    return (
      <div
        className={`p-6 flex-grow w-full grid gap-2 overflow-y-auto auto-rows-min no-scrollbar ${gridStyle.toString()}`}>
        {
          Array.from(Array(12).keys()).map(i => (
            <div key={`loading_item_${i}`} className="bg-slate-600 animate-pulse rounded-md min-h-[160px]">
            </div>
          ))
        }
      </div>
    );
  }

  if (error) {
    return <div>Error loading NFT owners</div>;
  }

  if (data.length === 0) {
    return (
      <div className="p-2 flex-grow w-full overflow-y-auto auto-rows-min no-scrollbar">
        <div className="w-full p-6">
          No tokens owned from this collection
        </div>
      </div>
    )
  }

  return (
    <>
      {!address && <ConnectWallet/>}
      {address && hasMounted && (
        <div className="flex w-full h-full overflow-y-auto">
          <div className="flex-1 p-2">
            <OwnerTokens
              id="locked"
              {...lockedSelected}
              className="!border-none !bg-transparent h-full"
              noTokensText="No locked NFTs"
              contractAddress={nftAddress}
              owner={address}
              fetcher={async () =>
                data.filter(t => lockedNFTIds.includes(BigInt(t.id)))
              }
              gridStyle={gridStyle}
            />
          </div>
          <div className="flex flex-col items-center justify-center relative p-1">
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="h-full border border-neutral-content/50"></div>
            </div>
            <div className="relative p-3 rounded-full border border-white bg-[#090F17] z-10">
              {(lockedSelected.selectedTokenIds?.length || 0) > 0 ? (
                <FaUnlock size={20}/>
              ) : (unlockedSelected.selectedTokenIds?.length || 0) > 0 ? (
                <FaLock size={20}/>
              ) : (
                <IoMdSwap size={20}/>
              )}
            </div>
          </div>
          <div className="flex-1 p-2">
            <OwnerTokens
              id="unlocked"
              {...unlockedSelected}
              className="!border-none !bg-transparent h-full"
              noTokensText="No unlocked NFTs"
              contractAddress={nftAddress}
              owner={address}
              fetcher={async () =>
                data.filter(t => !lockedNFTIds.includes(BigInt(t.id)))
              }
              gridStyle={gridStyle}
            />
          </div>
        </div>
      )}
      <div className="w-full p-4 flex items-center justify-between gap-2">
        <div>
          <TransactionChain id="updateLockState"
                            enabled={!!address && anySelected()}
                            title={!anySelected() ? "Select NFTs to Start" : isLockedNFT()
                              ? <div className="flex flex-row items-center gap-2"><FaUnlock/> Unlock Your NFTs</div>
                              : <div className="flex flex-row items-center gap-2"><FaLock/> Lock Your NFTs</div>}
                            transactionChain={txnChain}
                            onSuccess={async (lastReceipt) => {
                              // TODO: useWaitForTransaction and reload queries
                              window.location.reload();
                            }} />
          {txnError.error && <ErrorPanel message={txnError.error}/>}
        </div>
      </div>
    </>
  );
};
