import {useRouter} from "next/router";
import {useEffect} from "react";

export function useStringQueryParam(key: string, defaultValue: string) {
  return useQueryParam(key, defaultValue, (v) => v);
}

export function useQueryParam<T extends string>(
  key: string,
  defaultValue: T,
  parser: (v: string) => T): [T, (v: T) => void]
{
  const router = useRouter();
  const value = router.query[key];
  const setValue = (newValue: string) => {
    router.replace({
      pathname: router.pathname,
      query: {
        ...router.query,
        [key]: newValue,
      }
    }, undefined, {shallow: true});
  }

  useEffect(() => {
    if (!value && router.isReady) {
      setValue(defaultValue);
    }
  }, [router.isReady]);

  return [
    (value ? parser(Array.isArray(value) ? value[0] : value) : defaultValue),
    setValue
  ];
}