import {PerpToken} from "@/components/Perps/types";
import React from "react";
import {FNFTStatsView} from "@/components/Fractions/FNFTStatsView";
import {FaAngleDown} from "react-icons/fa";
import {Modal, useModal} from "@/components/Modal";
import {FNFTTable} from "@/components/Fractions/FNFTTable";
import {AddressDisplay} from "@/components/Account/AddressDisplay";

export const FNFTHeader = ({token, onSelected}: {token: PerpToken | undefined, onSelected: (t: PerpToken) => any}) => {
  const tokenSelectionModal = useModal();

  return (
    <div className="responsive-flex justify-between items-center p-6 h-[160px] md:h-[100px]">
      <div className="flex flex-row gap-2 items-center">
        {
          !token &&
          <span className="bg-slate-600 animate-pulse rounded-md min-h-[100px] min-w-[200px]"/>
        }
        {
          token &&
          <div className="flex flex-row gap-4 items-center rounded-md hover:bg-glass cursor-pointer px-2 py-4"
               onClick={tokenSelectionModal.show}>
            <img
              src={token.imageUrl}
              alt={token.symbol}
              className="w-10 h-10 rounded-full border border-glass-focus"
            />
            <div className="text-xl lg:text-2xl">
              {token.name}
              <span className="text-neutral-content pl-2 text-base">
                  {token.symbol}
              </span>
            </div>
            <FaAngleDown size={22} className="mr-2"/>
          </div>
        }
        { token && <AddressDisplay address={token.nftAddress!} displayIcon={true} /> }
      </div>
      {
        token &&
        <FNFTStatsView address={token.address}/>
      }
      <Modal {...tokenSelectionModal} title="Collections">
        <FNFTTable onSelected={t => {
          onSelected(t);
          tokenSelectionModal.hide();
        }} />
      </Modal>
    </div>
  );
}