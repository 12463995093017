import {FractionalNFT} from "@/components/Fractions/types";
import {useAccount} from "wagmi";
import {ChainItem, TransactionChain} from "@/components/Transactions/TransactionChain";
import {EthPrice} from "@/components/Perps/EthPrice";
import React, {useContext} from "react";
import {useQuery} from "@tanstack/react-query";
import {fetchFractionBuyOrder} from "@/api/fractionDataFetcher";
import {AddressZero} from "@ethersproject/constants";
import {useUserSelection} from "@/contexts/UserSelectionContext";
import {TransactionButtonProps} from "@/components/Transactions/TransactionButton";
import {UserBalanceContext} from "@/contexts/UserBalanceContext";
import {formatEther} from "@/util/converters";

export interface Props {
  tokenAddress: string,
  items: FractionalNFT[],
  sweepAmount: number
}
export const FNFTBuyButton = ({tokenAddress, items, sweepAmount}: Props) => {
  const {address} = useAccount();
  const {userSelections} = useUserSelection();
  const { balance } = useContext(UserBalanceContext);

  const totalPrice =
    items
      .filter((_, i) => i < sweepAmount)
      .reduce((acc, item) => acc + item.price, 0) || 0;

  const hasSufficientBalance = () => {
    if (!balance) {
      return false;
    }

    const balanceRaw = formatEther(balance);
    return balanceRaw >= totalPrice;
  }
  
  const transactionChain: ChainItem[] = [
    {
      name: "Complete Purchase",
      description: "Complete the purchase of the selected items",
      buttonQuery: () => useQuery({
        queryKey: ['buy_fnfts', tokenAddress, sweepAmount, userSelections.slippage, address],
        queryFn: async () => {
          const order = await fetchFractionBuyOrder({
            address: tokenAddress,
            recipient: address || AddressZero,
            numNFTs: sweepAmount,
            maxSlippage: userSelections.slippage
          });
          const buttonProps: TransactionButtonProps = {
            id: "buy_fnfts",
            loadingText: "Purchasing Items",
            ...order.callData,
            enabled: true,
            autoStart: true
          };
          return buttonProps;
        },
        enabled: sweepAmount > 0 && address !== undefined,
        gcTime: 0,
      }),
    }
  ];

  return (
    <TransactionChain
      className="min-w-[200px]"
      id="buy_fnfts"
      enabled={sweepAmount > 0 && address !== undefined && hasSufficientBalance()}
      title={
        <div className="flex flex-row gap-2 items-center">
          {
            (sweepAmount > 0 && !hasSufficientBalance()) ?
            "Insufficient Balance" : <>
                {
                  sweepAmount === 0
                    ? "Select an item"
                    : sweepAmount === 1
                      ? "Buy 1 Item"
                      : `Buy ${sweepAmount} Items`
                }
                { totalPrice > 0 && <EthPrice value={totalPrice} /> }
              </>
          }
        </div>
      }
      transactionChain={transactionChain}
      onSuccess={() => window.location.reload()} />
  )
}
