import React from "react";
import {ImWarning} from "react-icons/im";
import {twMerge} from "tailwind-merge";

export const WarningPanel = ({message, className}: {message: string, className?: string | undefined}) => {
  return (
    <div className={twMerge("flex flex-row items-center justify-center gap-2 text-warning rounded-md p-4", className || '')}>
      <div>
        <ImWarning size={12} />
      </div>
      <div>{message}</div>
    </div>
  );
}