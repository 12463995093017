import {useAccount} from "wagmi";
import {ChainItem, TransactionChain} from "@/components/Transactions/TransactionChain";
import {EthPrice} from "@/components/Perps/EthPrice";
import {formatValue} from "@/util/converters";
import {BigNumber} from "ethers";
import {SwapQuote} from "@/components/Perps/types";
import {useUserSelection} from "@/contexts/UserSelectionContext";
import {AddressZero} from "@ethersproject/constants";
import {FractionalNFTRouterAbi} from "@/contract/FractionalNFTRouterAbi";
import {Address} from "viem";
import {TokenApprovalButton} from "@/components/ERC721/TokenApprovalButton";
import {FRACTIONAL_NFT_ROUTER} from "@/util/constants";
import React from "react";
import {FaEthereum} from "react-icons/fa6";

export interface Props {
  nftAddress: string,
  items: BigNumber[] | [],
  swapQuote: SwapQuote
}

export const FNFTSellButton = ({nftAddress, items, swapQuote}: Props) => {
  const {address} = useAccount();
  const {userSelections} = useUserSelection();

  const BPS = 10_000n;
  const quoteAmount = swapQuote.quoteAmount || 0n;
  const amountOutMin = BigInt(quoteAmount) * (BPS - BigInt(userSelections.slippage)) / BPS;
  const poolFee = swapQuote.swapConfig?.poolFee || 0;
  const deadline = Math.round(new Date().getTime() / 1000) + swapQuote.swapConfig?.orderDuration || 0;

  const transactionChain: ChainItem[] = [
    {
      name: "Allow NFT Transfer",
      description: "Allow the transfer of your NFTs.",
      stepRenderer: (onSuccess, onFailure) =>
        <TokenApprovalButton
          autoStart={true}
          to={FRACTIONAL_NFT_ROUTER}
          singleToken={false}
          contractAddress={nftAddress}
          approvalChanged={approved => {
            if (approved) {
              onSuccess();
            }
          }}
          className="capitalize text-xs w-full"
        />
    },
    {
      name: "Complete Sell NFTs",
      description: "Complete the sale of the selected items",
      button: {
        id: "sell_fnfts",
        loadingText: "Selling Items",
        addressOrName: FRACTIONAL_NFT_ROUTER,
        contractInterface: FractionalNFTRouterAbi,
        functionName: "sellFractionalizedNFTs",
        args: [nftAddress, items, 3000, amountOutMin, deadline],
      }
    }
  ];

  return (
    <TransactionChain
      className="min-w-[200px]"
      id="sell_fnfts"
      enabled={items?.length > 0 && address !== (AddressZero || undefined) && quoteAmount > 0}
      title={
        <div className="flex flex-row gap-1 items-center">
          Sell for {formatValue(amountOutMin, 4)} <FaEthereum size={12}/>
        </div>
      }
      transactionChain={transactionChain}
      onSuccess={() => window.location.reload()}
    />
  )
}
