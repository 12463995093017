import {isBlast} from "@/util/constants";

export interface Props {
  width: string;
  height: string;
  pairAddress: string;
  theme?: "dark" | "light";
  chartType?: "0" | "1" | "2" | "3" | "8" | "9" | "10";
  chartResolution?: "1" | "3" | "5" | "15" | "30" | "60" | "120" | "240" | "720" | "1D" | "3D" | "1W" | "1M";
  drawingToolbars?: boolean;
  chartInUsd?: boolean;
  hideSwaps?: boolean;
}

export const CoinGeckoChart = ({ width, height, pairAddress, hideSwaps} : Props) => {
  return (
    <iframe
      id="geckoterminal-embed"
      title="CoinGecko Trading Chart"
      width={width}
      height={height}
      src={`https://www.geckoterminal.com/${isBlast ? "blast" : "eth"}/pools/${pairAddress}?embed=1&info=0&swaps=${hideSwaps ? 0 : 1}`}
      allowFullScreen
    ></iframe>
  )
}
