import {Button, ButtonType} from "@/components/Button";
import {FaGear} from "react-icons/fa6";
import React from "react";
import {Modal, useModal} from "@/components/Modal";
import {SlippageInput} from "@/components/Perps/slippage/SlippageInput";
import {useUserSelection} from "@/contexts/UserSelectionContext";

export const SlippageButton = () => {
  const slippageModal = useModal();
  const { userSelections, handleSelection } = useUserSelection();

  return (
    <>
      <Button
        buttonType={ButtonType.NEUTRAL}
        onClick={slippageModal.show}
        className="!border-none !px-2 !py-2 flex items-center"
      >
        {(userSelections.slippage / 100)}% slippage <FaGear />
      </Button>
      <Modal {...slippageModal} title="Set Slippage">
        <SlippageInput initialSlippage={userSelections.slippage}
                       onSlippageChanged={s => {
                         handleSelection('slippage', s);
                         slippageModal.hide();
                       }}/>
      </Modal>
    </>
  )
}