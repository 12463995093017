import {useQuery} from "@tanstack/react-query";
import {fetchOwnerTokens} from "@/api/datafetcher";
import {Item} from "@/types/dkoda_types";
import {BigNumber} from "ethers";
import {Button, ButtonType} from "@/components/Button";
import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {LoadingSpinner} from "@/components/LoadingSpinner";
import {BiLockAlt} from "react-icons/bi";
import {twMerge} from "tailwind-merge";
import {FaSquareCheck} from "react-icons/fa6";
import {IoReload} from "react-icons/io5";
import {TiRefresh} from "react-icons/ti";
import {MdRefresh} from "react-icons/md";
import {GridStyle} from "@/pages/collect";

export const useSelectNFTs = () => {
  const [selectedTokenIds, onChange] = useState<BigNumber[]>();
  return {selectedTokenIds, onChange}
}

export interface Props {
  className?: string,
  owner: string;
  contractAddress: string;
  selectedTokenIds?: BigNumber[];
  onChange?: (selectedTokenIds: BigNumber[]) => void;
  multiSelect?: boolean;
  enabled?: boolean;
  gridStyle?: GridStyle;
  fetcher?: (contractAddress: string, owner: string) => Promise<Item[]>,
  id?: string;
  tokenIsLocked?: (tokenId: string) => boolean;
  noTokensText?: string;
  autoSelectAll?: boolean;
}

export const OwnerTokens = ({
  owner,
  contractAddress,
  selectedTokenIds = [],
  onChange = () => {},
  multiSelect = true,
  enabled = true,
  gridStyle = GridStyle.GridColsFillLg,
  fetcher = async (a, b) => await fetchOwnerTokens(a, b, undefined),
  id = "ownerTokens",
  tokenIsLocked,
  noTokensText,
  className,
  autoSelectAll = false
}: Props) => {
  const checkTokenLock = (nft: Item) => {
    return tokenIsLocked && tokenIsLocked(nft.tokenId.toString());
  }

  const selectAllOnClick = () => {
    if (selectedTokenIds.length === data?.filter(i => !checkTokenLock(i)).length) {
      onChange([]);
    } else {
      onChange((data || [])
        .filter(nft => !checkTokenLock(nft))
        .map(nft => BigNumber.from(nft.tokenId)));
    }
  }

  const { data, isLoading, isRefetching, refetch, isSuccess } = useQuery({
    queryKey: [id, contractAddress, owner],
    queryFn: async () => await (fetcher || fetchOwnerTokens)(contractAddress, owner),
    gcTime: 1000 * 60,
    staleTime: 1000 * 60 * 1
  });

  useEffect(() => {
    if (autoSelectAll && isSuccess) {
      selectAllOnClick();
    }
  }, [isSuccess, autoSelectAll]);

  const renderOwnerNft = (nft: Item) => {
    const nftId = BigNumber.from(nft.tokenId);
    const index = selectedTokenIds.findIndex((id) => id.eq(nftId));
    const selected = index >= 0;
    return (
      <div key={nft.id}
        className={classNames("relative overflow-hidden flex flex-col items-center rounded-md bg-glass", {
          "hover:bg-glass-focus hover:cursor-pointer": enabled && !checkTokenLock(nft),
          "outline-call hover:outline-white outline-2 outline": selected,
        })}
        onClick={() => {
          if (!enabled || !onChange || checkTokenLock(nft)) {
            return;
          }
          if (selected) {
            // Unselect
            onChange(selectedTokenIds.filter((id) => !id.eq(nftId)));
          } else {
            if (multiSelect) {
              onChange([...selectedTokenIds, nftId]);
            } else {
              onChange([nftId]);
            }
          }
        }}
      >
        <img alt="asset image" src={nft.imageThumbnailUrl || nft.imageUrl} />
        {
          checkTokenLock(nft) &&
          <div className="absolute m-1 p-1 w-32 h-32 bg-glass-focus rounded-md">
            <BiLockAlt />
          </div>
        }
        {
          selected &&
          <div className="absolute m-1 p-1 w-full h-full bg-glass-focus rounded-md text-call flex justify-end">
            <FaSquareCheck />
          </div>
        }
        <div className="label p-3 flex flex-row items-center justify-center">

          <span>#{nft.tokenId}</span>
        </div>
      </div>
    );
  };

  const sort = (a: Item, b: Item): number => {
    if (!tokenIsLocked) {
      return 0;
    }
    const aLocked = checkTokenLock(a);
    const bLocked = checkTokenLock(b);
    if (aLocked && bLocked) {
      return 0;
    }
    if (aLocked) {
      return 1;
    }
    if (bLocked) {
      return -1;
    }
    return a.tokenId - b.tokenId;
  }

  return (
    <div className={twMerge("standard-stack standard-frame px-2 pt-2 items-center justify-between", className || '')}>
      {
        (!isLoading && (!data || data.length == 0)) ?
          <div className="w-full p-4">
            {noTokensText || "No tokens owned from this collection"}
          </div> :
          <div className={`w-full grid gap-2 overflow-y-auto auto-rows-min no-scrollbar ${gridStyle} p-1`}>
            {
              (isLoading || isRefetching) &&
              Array.from(Array(6).keys()).map(i => (
                <div key={`loading_item_${i}`} className="bg-slate-600 animate-pulse rounded-md min-h-[160px]">
                </div>
              ))
            }
            {!(isLoading || isRefetching) && data && data.sort(sort).map(renderOwnerNft)}
          </div>
      }
      {
        !isLoading && enabled && multiSelect &&
        <div className="w-full flex flex-row gap-2 justify-between">
          <Button buttonType={ButtonType.NEUTRAL} onClick={() => refetch()}>
            <MdRefresh className="font-bold" size={18} /> Reload
          </Button>
          <Button buttonType={ButtonType.SECONDARY} onClick={selectAllOnClick}>
            { selectedTokenIds.length === data?.filter(i => !checkTokenLock(i)).length ? `Unselect All (${selectedTokenIds.length})` : `Select All (${selectedTokenIds.length})` }
          </Button>
        </div>
      }
    </div>
  );
};
