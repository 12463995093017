import {NextPageWithLayout} from "@/pages/_app";
import React, {ReactElement, useEffect, useState} from "react";
import {PrimaryLayout} from "@/layouts/PrimaryLayout";

import {FractionTokenListings} from "@/components/Fractions/FractionTokenListings";
import {FNFTHeader} from "@/components/Fractions/FNFTHeader";
import {useQuery} from "@tanstack/react-query";
import {PerpToken} from "@/components/Perps/types";
import {fetchFNFT} from "@/api/fractionDataFetcher";
import {PrimaryLayout2} from "@/layouts/Primary2Layout";
import {CoinGeckoChart} from "@/components/Perps/CoinGeckoChart";
import {useStringQueryParam} from "@/hooks/useQueryParam";
import {isBlast} from "@/util/constants";
import classNames from "classnames";
import {MdOutlineSell, MdSell} from "react-icons/md";
import {BsFillGrid3X3GapFill, BsFillGridFill} from "react-icons/bs";
import {FNFTPortfolio} from "@/components/Fractions/FNFTPortfolio";
import {FNFTLockAndUnlock} from "@/components/Fractions/FNFTLockAndUnlock";
import {FaChartLine, FaUnlock} from "react-icons/fa";
import {useUserSelection} from "@/contexts/UserSelectionContext";
import {useScreen} from "@/util/useScreen";
import {Tabs} from "@/components/Tabs";
import {DropdownOption} from "@/components/Dropdown";
import {useIsDesktop} from "@/hooks/useIsDesktop";

type Tab = {
  id: string;
  name: string;
  icon: any;
}
const tabs: DropdownOption<string>[] = [
  { value: "buy", label: "Buy", icon: <MdSell /> },
  { value: "sell", label: "Sell", icon: <MdOutlineSell /> },
  { value: "lockUnlock", label: "Unlock/Lock", icon: <FaUnlock /> },
]

const mobileTabs: DropdownOption<string>[] = [
  ...tabs,
  { label: "Chart", value: "chart", icon: <FaChartLine /> }
]

export enum GridStyle {
  GridColsFillLg = 'grid-cols-fill-lg',
  GridColsFillMd = 'grid-cols-fill-md',
  GridColsFillSm = 'grid-cols-fill-sm',
}

const Page: NextPageWithLayout = () => {
  const isLargeScreen = useScreen('lg');
  const isDesktop = useIsDesktop();

  const [token, setToken] = useStringQueryParam('token', isBlast ? "bPEPE" : "PANDORA");
  const [currentTab, setCurrentTab] = useStringQueryParam('t', tabs[0].value);
  const { userSelections, handleSelection } = useUserSelection();
  const [gridStyle, setGridStyle] = useState<GridStyle>(userSelections.collectGridStyle as GridStyle || GridStyle.GridColsFillMd);

  const fnftQuery = useQuery<PerpToken>({
    queryKey: ["fnft", token],
    queryFn: async () => fetchFNFT(token),
    gcTime: 1000 * 60 * 10, // 10 minutes
  });

  const gridOptions = isLargeScreen ? [GridStyle.GridColsFillLg, GridStyle.GridColsFillMd] : [GridStyle.GridColsFillMd, GridStyle.GridColsFillSm];

  useEffect(() => {
    setGridStyle(gridStyle);
  }, [gridStyle]);

  const renderGridButton = (newStyle: GridStyle, icon: React.ReactNode) => (
    <div onClick={() => setGridStyle(newStyle)}
         className={classNames('cursor-pointer hover:scale-110', {
           'text-green-400': gridStyle === newStyle,
           'text-neutral-content hover:text-white': gridStyle !== newStyle,
         })}>{icon}</div>
  );

  return (
    <div className="flex flex-col divide-y divide-neutral-content/20 h-full">
      <FNFTHeader token={fnftQuery.data} onSelected={t => setToken(t.symbol)} />
      {
        isDesktop &&
        <div className="w-full flex-grow grid grid-cols-3 grid-rows-2 overflow-hidden">
          <div className="col-span-3 md:col-span-2 row-span-2 h-full border-r border-neutral-content/20">
            {
              fnftQuery.data &&
              <div className="flex flex-col flex-grow justify-between divide-y divide-neutral-content/20 h-full">
                <div className="responsive-flex md:justify-between">
                  <Tabs<string>
                    className="w-full h-[44px] rounded-none border-0"
                    options={tabs}
                    value={currentTab}
                    onChange={setCurrentTab} />
                  <div className="flex flex-row gap-2 items-center justify-center p-2">
                    {
                      gridOptions.map((newStyle, i) =>
                        renderGridButton(
                          newStyle,
                          i === 0 ? <BsFillGridFill size={20} /> : <BsFillGrid3X3GapFill size={20} />
                        ))
                    }
                  </div>
                </div>
                {
                  currentTab === "buy" &&
                  <FractionTokenListings tokenAddress={fnftQuery.data.address} gridStyle={gridStyle} />
                }
                {
                  currentTab === "sell" && <FNFTPortfolio token={fnftQuery.data} gridStyle={gridStyle}/>
                }
                {
                  currentTab === "lockUnlock" && <FNFTLockAndUnlock token={fnftQuery.data} gridStyle={gridStyle}/>
                }
              </div>
            }
          </div>
          <div className="col-span-3 md:col-span-1 row-span-2 min-h-[800px] md:min-h-[300px]">
            {
              fnftQuery.data &&
              <CoinGeckoChart height={"100%"} width={"100%"} pairAddress={fnftQuery.data.pairAddress} key={fnftQuery.data.pairAddress} />
            }
            {
              fnftQuery.isLoading &&
              <div className="w-full h-full animate-pulse">
              </div>
            }
          </div>
        </div>
      }
      {
        !isDesktop &&
        <>
          <div className="w-full flex-grow overflow-auto">
            {
              fnftQuery.isLoading &&
              <div className="w-full h-full animate-pulse">
              </div>
            }
            {
              currentTab === "buy" && fnftQuery.data &&
              <FractionTokenListings tokenAddress={fnftQuery.data.address} gridStyle={gridStyle} />
            }
            {
              currentTab === "sell" && fnftQuery.data &&
              <FNFTPortfolio token={fnftQuery.data} gridStyle={gridStyle} />
            }
            {
              currentTab === "lockUnlock" && fnftQuery.data &&
              <FNFTLockAndUnlock token={fnftQuery.data} gridStyle={gridStyle} />
            }
            {
              currentTab === "chart" && fnftQuery.data &&
              <CoinGeckoChart height={"100%"} width={"100%"} pairAddress={fnftQuery.data.pairAddress} key={fnftQuery.data.pairAddress} />
            }
          </div>
          <Tabs<string>
            className="w-full min-h-[66px] !border-0"
            options={mobileTabs}
            value={currentTab}
            onChange={setCurrentTab} />
        </>
      }
    </div>
  )
}

Page.getLayout = function getLayout(page: ReactElement) {
  return <PrimaryLayout2 skipGeofence={true} fixedHeight={true} grow={true} hidePadding={true}>
    {page}
  </PrimaryLayout2>;
};

export default Page;
